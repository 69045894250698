<template>
  <div><vet-list /></div>
</template>

<script>
import VetList from "./components/VetList/VetList.vue";
export default {
  components: { VetList },
};
</script>

<style></style>
