<template>
  <b-modal
    id="add-new-vet-sidebar"
    centered
    :visible="isAddNewVetSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    @ok="$refs.submitButtonRef.click()"
    @hidden="formValidation(resetblankvet).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("vetAdd") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankvet
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- vetName -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="vetName"
          >
            <b-form-group :label="$t('vetName')" label-for="vetName">
              <b-form-input
                id="vetName"
                v-model="blankVetData.vetName"
                autofocus
                :state="
                  formValidation(resetblankvet).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- mobile -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="mobile"
          >
            <b-form-group :label="$t('mobile')" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="blankVetData.mobile"
                autofocus
                :state="
                  formValidation(resetblankvet).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- email -->
          <validation-provider
            #default="validationContext"
            rules="required|email"
            name="email"
          >
            <b-form-group :label="$t('email')" label-for="email">
              <b-form-input
                id="email"
                v-model="blankVetData.email"
                autofocus
                :state="
                  formValidation(resetblankvet).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- description -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="description"
          >
            <b-form-group :label="$t('description')" label-for="description">
              <b-form-input
                id="description"
                v-model="blankVetData.description"
                autofocus
                :state="
                  formValidation(resetblankvet).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // BFormCheckbox,
    // BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewVetSidebarActive",
    event: "update:is-add-new-vet-sidebar-active",
  },
  props: {
    isAddNewVetSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankVetData: {
        id: 0,
        vetName: "",
        mobile: "",
        email: "",
        description: "",
        companyid: getUserData().companyid,
      },
      formValidation: formValidation,
    };
  },
  async mounted() {},
  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-vet-sidebar-active", val);
      this.resetblankvet();
    },
    onSubmit() {
      store
        .dispatch("definitionsModule/addVet", {
          vet: this.blankVetData,
          plantid: this.$store.state.app.selectedPlantId,
        })
        .then((val) => {
          if (val != false) {
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-vet-sidebar-active", false);
          }
        });
    },
    async resetblankvet() {
      this.blankVetData = {
        id: 0,
        vetName: "",
        mobile: "",
        email: "",
        description: "",
        companyid: getUserData().companyid,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-vet-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
